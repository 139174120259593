import * as React from 'react';

import MainContainer from '../Containers/MainContainer';
import Text from '../Modules/Text';

import Colors from '../../styles/Colors';

export default function TeamsTitle() {
  return (
    <MainContainer>
      <div
        style={{
          height: 8,
          width: '100%',
          backgroundColor: Colors.WHITE,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            width: 160,
            height: 10,
            backgroundColor: Colors.BLACK,
            marginTop: -1,
          }}
        >
          <Text
            textAlign="center"
            marginTop="-9px"
          >
            Teams
          </Text>
        </div>
      </div>
    </MainContainer>
  );
}

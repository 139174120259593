import * as React from 'react';

import UseDimensions from '../../services/UseDimensions';

export default function SmallContainer({
  children,
}) {
  const size = UseDimensions();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: size.width < 460 ? 'column' : 'row',
        gap: size.width < 460 ? 10 : 15,
        marginBottom: size.width < 460 ? 30 : 15,
      }}
    >
      {children}
    </div>
  );
}

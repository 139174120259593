import * as React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import Colors from '../../../styles/Colors';

import NavItem from './NavItem';

export default function SideMenu() {
  const { t } = useTranslation();

  const [menu, setMenu] = React.useState(true);

  return (
    <>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        flex: 1,
      }}
      >
        <button
          onClick={() => setMenu(!menu)}
          aria-label="Menu"
          type="button"
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 9,
            width: 42,
            height: 'auto',
            marginTop: 0,
            cursor: 'pointer',
            zIndex: 2000,
            backgroundColor: 'transparent',
            border: 'none',
          }}
        >
          <span style={{
            backgroundColor: Colors.WHITE,
            borderRadius: 10,
            height: 4,
            width: menu ? 30 : 20,
            marginLeft: menu ? 6 : 16,
            transition: '.2s ease-in-out',
            transform: menu ? 'rotate(0deg)' : 'rotate(45deg)',
            marginRight: menu ? 0 : 1,
          }}
          />
          <span style={{
            backgroundColor: Colors.WHITE,
            borderRadius: 10,
            height: 4,
            width: menu ? 40 : 40,
            marginLeft: menu ? -4 : -10,
            transition: '.2s ease-in-out',
            marginTop: menu ? 0 : -7,
          }}
          />
          <span style={{
            backgroundColor: Colors.WHITE,
            borderRadius: 10,
            height: 4,
            width: menu ? 22 : 20,
            marginLeft: menu ? 13 : 16,
            transition: '.2s ease-in-out',
            marginTop: menu ? 0 : -7,
            transform: menu ? 'rotate(0deg)' : 'rotate(-45deg)',
            marginRight: menu ? 0 : 1,
          }}
          />
        </button>
      </div>
      <div style={{
        width: '80vw',
        maxWidth: 350,
        height: '100vh',
        position: 'fixed',
        transform: menu ? 'translateX(100%)' : 'translateX(0)',
        transition: 'all .3s ease-in-out',
        right: 0,
        top: 0,
        borderLeft: `solid 5px ${Colors.BLACK_PURE}`,
        zIndex: 1000,
      }}
      >
        <div style={{
          position: 'relative',
          height: '100%',
          widht: '100%',
        }}
        >
          <div style={{
            background: 'inherit',
            backgroundColor: Colors.BLACK,
            overflow: 'hidden',
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: 0,
          }}
          />
          <button
            style={{
              position: 'absolute',
              zIndex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              paddingTop: 90,
              paddingLeft: 20,
              height: '100%',
              minHeight: 600,
              backgroundColor: 'transparent',
              border: 'none',
              width: '100%',
            }}
            type="button"
            onClick={() => setMenu(true)}
          >
            <NavItem
              path="/"
              title={parse(t('Navigation.Menu.home'))}
              mobile
            />
            <NavItem
              path="/#teams"
              title="Teams"
              mobile
            />
            <NavItem
              path="/#locatie"
              title={parse(t('Navigation.Menu.location'))}
              mobile
            />
            <NavItem
              path="/#contact"
              title={parse(t('Navigation.Menu.contact'))}
              mobile
            />
          </button>
        </div>
      </div>
    </>
  );
}

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import SmallContainer from '../Containers/SmallContainer';

import Text from '../Modules/Text';

import Breakpoint from '../../styles/Breakpoints';
import Colors from '../../styles/Colors';

import UseDimensions from '../../services/UseDimensions';

import Buttons from '../Modules/Text/Buttons';

export default function Address() {
  const { t } = useTranslation();
  const size = UseDimensions();

  const DATA = [
    {
      id: 0,
      day: 'Maandag',
      time: '19:00 - 21:00',
    },
    {
      id: 0,
      day: 'Dinsdag',
      time: '17:00 - 18:00',
    },
    {
      id: 0,
      day: 'Woensdag',
      time: '20:30 - 22:00',
    },
    {
      id: 0,
      day: 'Donderdag',
      time: '17:00 - 18:00',
    },
    {
      id: 0,
      day: 'Vrijdag',
      time: 'Gesloten',
    },
  ];

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: size.width < Breakpoint.md ? 50 : 0,
          borderBottom: `solid 4px ${Colors.BLACK_MEDIUM}`,
          paddingBottom: 5,
          height: 30,
          marginBottom: 20,
        }}
      >
        <Text>{parse(t('Address.title'))}</Text>
      </div>
      <div>
        <Text
          fontFamily="Poppins"
          fontWeight="300"
        >
          {parse(t('Address.training_text'))}
        </Text>
        <br />
        {
          DATA.map((item) => (
            <SmallContainer
              key={item.id}
            >
              <div
                style={{
                  flex: 1,
                }}
              >
                <Text>
                  {item.day}
                </Text>
              </div>
              <div
                style={{
                  flex: 1,
                }}
              >
                <Text>
                  {`${size.width < 460 ? '-' : ''} ${item.time}`}
                </Text>
              </div>
            </SmallContainer>
          ))
        }
        <div
          style={{
            marginTop: 50,
          }}
        >
          <Text
            fontFamily="Poppins"
            fontWeight="300"
          >
            {parse(t('Address.address'))}
          </Text>
          <div style={{ marginTop: 20, marginBottom: 60 }}>
            <Buttons
              type="secondary"
              title="Open in Google Maps"
              onClick={() => window.open('https://goo.gl/maps/tP1fb8oTzkudstgNA')}
            />
          </div>
        </div>
      </div>
    </>
  );
}

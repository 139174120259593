import * as React from 'react';

import Colors from '../../styles/Colors';

import Text from '../Modules/Text';

export default function Team({
  title,
  image,
}) {
  return (
    <>
      <div
        style={{
          paddingBottom: 5,
          height: 30,
          width: '100%',
          borderBottom: `solid 4px ${Colors.BLACK_MEDIUM}`,
          marginBottom: 25,
        }}
      >
        <Text
          textAlign="left"
        >
          {title}
        </Text>
      </div>
      {
        image !== undefined ? (
          <div
            style={{
              width: '100%',
              minHeight: 350,
              maxHeight: 400,
              height: '100%',
              backgroundImage: `url('${image}')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
        ) : (
          <div
            style={{
              width: 'auto',
              minHeight: 350,
              maxHeight: 400,
              height: '100%',
              backgroundColor: Colors.BLACK_MEDIUM,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              paddingLeft: 60,
              paddingRight: 60,
            }}
          >
            <Text
              textAlign="center"
            >
              Teamfoto binnenkort beschikbaar
            </Text>
          </div>
        )
      }
    </>
  );
}

import * as React from 'react';

import axios from 'axios';

import Config from '../Config';

import Container from '../components/Containers/Container';
import MainContainer from '../components/Containers/MainContainer';

import Landing from '../components/Sections/Landing';
import LastGameResult from '../components/Sections/LastGameResult';
import UpcomingGame from '../components/Sections/UpcomingGame';
import Team from '../components/Sections/Team';
import TeamsTitle from '../components/Sections/TeamsTitle';
// import Standings from '../components/Sections/Standings';
import Address from '../components/Sections/Address';
import Map from '../components/Sections/Map';
import Contact from '../components/Sections/Contact';
import Board from '../components/Sections/Board';

import Breakpoint from '../styles/Breakpoints';
import UseDimensions from '../services/UseDimensions';

import HerenTeamImage from '../assets/img/Heren1.jpg';

export default function IndexPage() {
  const size = UseDimensions();
  const [games, setGames] = React.useState([]);

  React.useEffect(() => {
    axios.get(`${Config.apiUrl}/wedstrijden.php`)
      .then((response) => {
        if (response.data === null) setGames([]);
        else setGames(response.data);
      });
  }, []);

  const playedGames = games.filter((i) => i.status === 'gespeeld');
  const lastGame = playedGames[playedGames.length - 1] ?? {
    thuis_ploeg: '', score_thuis: 0, uit_ploeg: '', score_uit: 0, winst: '',
  };

  const toPlayGames = games.filter((i) => i.status === 'planning');
  const toPlayHomeGames = toPlayGames.filter((i) => i.loc_naam === 'Sporthal Marienhoeve');

  const upcomingGame = toPlayGames[0] ?? {
    thuis_ploeg: '', uit_ploeg: '', loc_naam: '', datum: '',
  };

  const breakpoint = Breakpoint.md;

  return (
    <>
      <Landing />

      <div
        style={{
          marginTop: -200,
        }}
      >
        {
          (lastGame.thuis_ploeg === '' && lastGame.uit_ploeg === '')
            ? (
              <UpcomingGame
                home={upcomingGame.thuis_ploeg}
                guest={upcomingGame.uit_ploeg}
                location={upcomingGame.loc_naam}
                date={upcomingGame.datum}
                isVisible={toPlayGames[0] !== undefined}
              />
            ) : (
              <LastGameResult
                home={[lastGame.thuis_ploeg, lastGame.score_thuis]}
                guest={[lastGame.uit_ploeg, lastGame.score_uit]}
                win={lastGame.winst}
              />
            )
        }
      </div>
      <div id="teams" />

      <div
        style={{
          marginTop: 150,
        }}
      >
        <TeamsTitle />
      </div>

      <div
        style={{
          marginTop: 60,
        }}
      >
        <MainContainer>
          <Container share={[50, 50]}>
            <Team
              title="XU12-1"
              image={undefined}
            />
            <Team
              title="XU12-2"
              image={undefined}
            />
          </Container>
        </MainContainer>
      </div>

      <div
        style={{
          marginTop: size.width < breakpoint ? 50 : 100,
        }}
      >
        <MainContainer>
          <Container share={[50, 50]}>
            <Team
              title="MU16"
              image={undefined}
            />
            <Team
              title="Heren 1"
              image={HerenTeamImage}
            />
          </Container>
        </MainContainer>
      </div>

      <div id="locatie" />

      <div
        style={{
          marginTop: size.width < breakpoint ? 100 : 200,
        }}
      >
        <MainContainer>
          <Container share={[50, 50]} invert>
            <Map />
            <Address firstGame={toPlayHomeGames[0]} />
          </Container>
        </MainContainer>
      </div>
      <div id="contact" />

      <div
        style={{
          marginTop: 120,
        }}
      >
        <MainContainer>
          <Container share={[60, 40]}>
            <Contact />
            <Board />
          </Container>
        </MainContainer>
      </div>
    </>
  );
}

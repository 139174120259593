import * as React from 'react';

import Text from '../Modules/Text';

import Colors from '../../styles/Colors';

export default function Board() {
  const MEMBERS = [
    {
      id: 0,
      name: 'Paul Oosterman',
      role: 'Voorzitter',
      primary: true,
    },
    {
      id: 1,
      name: 'Johan Tijhuis',
      role: 'Penningmeester',
      primary: true,
    },
    {
      id: 2,
      name: 'Vacant',
      role: 'Secretaris',
      primary: true,
    },
    {
      id: 3,
      name: 'Ralf Meijerink',
      role: 'Activiteiten & Fun',
      primary: false,
    },
    {
      id: 4,
      name: 'Lennart Pol',
      role: 'Basketbal Techniek',
      primary: false,
    },
    {
      id: 5,
      name: 'Erik Kohlenberg',
      role: 'Leden & Competitie zaken',
      primary: false,
    },
    {
      id: 6,
      name: 'Theo Meijerink',
      role: 'Scheidsrechters zaken',
      primary: false,
    },
    {
      id: 7,
      name: 'Jasper de Jong',
      role: 'Sponsoring',
      primary: false,
    },
  ];

  return (
    <div>
      <div
        style={{
          paddingBottom: 5,
          height: 30,
          borderBottom: `solid 4px ${Colors.BLACK_MEDIUM}`,
          marginBottom: 25,
        }}
      >
        <Text
          textAlign="left"
        >
          Bestuur
        </Text>
      </div>
      <div>
        {
          MEMBERS.filter((item) => item.primary).map((item) => (
            <Text
              key={item.id}
              fontFamily="Poppins"
              fontWeight="300"
            >
              {`${item.role} - ${item.name}`}
            </Text>
          ))
        }
        <br />
        {
          MEMBERS.filter((item) => item.primary === false).map((item) => (
            <Text
              key={item.id}
              fontFamily="Poppins"
              fontWeight="300"
            >
              {`${item.role} - ${item.name}`}
            </Text>
          ))
        }
      </div>
    </div>
  );
}

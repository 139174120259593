import * as React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import parse from 'html-react-parser';

import UseDimensions from '../../services/UseDimensions';

import Colors from '../../styles/Colors';

import MainContainer from '../Containers/MainContainer';
import Text from '../Modules/Text';

export default function UpcomignGame(props) {
  const { t } = useTranslation();
  const size = UseDimensions();

  const rm = (e) => e.replace(/(?:^|\W)MSE [1-9][0-9]?$|^100(?:$|\W)/, '');

  const days = ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'];
  const months = ['jan.', 'feb.', 'mrt.', 'apr.', 'mei', 'jun.', 'jul.', 'aug.', 'sept.', 'okt.', 'nov.', 'dec.'];

  const gameDate = moment(props.date ? props.date : 0)
    .toDate();

  const readableGameDate = `${days[gameDate.getDay()]} ${gameDate.getDate()} ${months[gameDate.getMonth()]}`;
  const readableGameTime = `${gameDate.getHours() + (gameDate.getHours() < 10 ? '0' : '')}:${gameDate.getMinutes()}${gameDate.getMinutes() < 10 ? '0' : ''}`;

  if (!props.isVisible) return null;

  return (
    <>
      <MainContainer>
        <div
          style={{
            height: 8,
            width: '100%',
            backgroundColor: Colors.WHITE,
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 100,
          }}
        >
          <div
            style={{
              width: 260,
              height: 10,
              backgroundColor: Colors.BLACK,
              marginTop: -1,
            }}
          >
            <Text
              textAlign="center"
              marginTop="-9px"
            >
              {parse(t('UpcomingGame.title'))}
            </Text>
          </div>
        </div>
      </MainContainer>

      <div
        style={{
          display: 'flex',
          flexDirection: size.width < 1200 ? 'column' : 'row',
        }}
      >

        <div
          style={{
            marginBottom: -25,
            width: size.width < 1200 ? '100%' : '33.333333333%',
          }}
        >
          <div
            style={{
              marginTop: size.width < 1200 ? 30 : 0,
              width: '100%',
              overflow: 'hidden',
              height: 150,
              display: 'flex',
              alignItems: 'center',
              justifyContent: size.width < 1200 ? 'center' : 'right',
            }}
          >
            <Text
              textAlign={size.width < 1200 ? 'center' : 'right'}
              color={Colors.BLACK_MEDIUM}
              fontSize="6.9em"
            >
              HOME
            </Text>
          </div>
          <MainContainer>
            <div
              style={{
                height: 150,
                marginTop: -150,
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              <Text
                textAlign={size.width < 1200 ? 'center' : 'right'}
                color={rm(props.home) === 'Bourgondie' ? Colors.ORANGE : Colors.WHITE}
              >
                {rm(props.home) === 'Bourgondie' ? 'Bourgondië' : rm(props.home)}
              </Text>
            </div>

          </MainContainer>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: size.width < 1200 ? 'column' : 'row',
            width: size.width < 1200 ? '100%' : '33.333333333%',
          }}
        >
          <Text
            textAlign="center"
          >
            {parse(t('LastGameResult.vs'))}
          </Text>
        </div>

        <div
          style={{
            marginTop: size.width < 1200 ? -50 : 0,
            width: size.width < 1200 ? '100%' : '33.333333333%',
          }}
        >
          <div
            style={{
              marginTop: size.width < 1200 ? 30 : 0,
              width: '100%',
              overflow: 'hidden',
              height: 150,
              display: 'flex',
              alignItems: 'center',
              justifyContent: size.width < 1200 ? 'center' : 'left',
            }}
          >
            <Text
              textAlign={size.width < 1200 ? 'center' : 'left'}
              color={Colors.BLACK_MEDIUM}
              fontSize="6.9em"
              width="auto"
            >
              AWAY
            </Text>
          </div>
          <MainContainer>
            <div
              style={{
                height: 150,
                marginTop: -150,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Text
                textAlign={size.width < 1200 ? 'center' : 'left'}
                color={rm(props.guest) === 'Bourgondie' ? Colors.ORANGE : Colors.WHITE}
              >
                {rm(props.guest) === 'Bourgondie' ? 'Bourgondië' : rm(props.guest)}
              </Text>
            </div>
          </MainContainer>
        </div>
      </div>
      <MainContainer>
        <div
          style={{
            height: 'auto',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              width: 'auto',
              minWidth: 300,
              height: 10,
              backgroundColor: Colors.BLACK,
              marginTop: -1,
              paddingLeft: 30,
              paddingRight: 30,
            }}
          >
            <Text
              textAlign="center"
              marginTop="-9px"
            >
              {`${readableGameDate} ${readableGameTime} - ${props.location}`}
            </Text>
          </div>
        </div>
      </MainContainer>
    </>
  );
}
